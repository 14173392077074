class RyderMarquee {
	constructor(el, direct = 0) {
		this.hero = $(el).parent().get(0)
		this.wrapper = el
		this.delta = 0
		this.transform = 0
		this.step = (device == 'mobile') ? 0.2 : 0.3
		this.direct = direct % 2
		this.hover = false

		if (this.direct == 1) {
			this.wrapper.style.transform = `translate3d(-${this.wrapper.getBoundingClientRect().width / 2}px, 0, 0)`;
			this.transform = -this.wrapper.getBoundingClientRect().width / 2
		}

		// $(el).hover(() => {
		// 	this.hover = true
		// }, () => {
		// 	this.hover = false
		// })
	}

	animate() {
		this.delay = 50;

		if (this.velo != null) {
			this.delta = lerp(this.delta, this.scrollY - this.velo, 0.1);
		}

		if (this.delta < 0) {
			this.delta = 0;
		}

		this.velo = this.scrollY;

		clearTimeout(this.timer);

		this.timer = setTimeout(this.clear, this.delay);


		if (!this.hover) {
			this.transform += this.step + (this.delta / 5);
		}

		if (this.direct == 1) {
			if (this.transform > 0) {
				this.transform = -this.wrapper.getBoundingClientRect().width / 2;
			}
			this.wrapper.style.transform = `translate3d(${this.transform}px, 0, 0)`;
		} else {
			if (this.transform > this.wrapper.getBoundingClientRect().width / 2) {
				this.transform = 0;
			}
			this.wrapper.style.transform = `translate3d(-${this.transform}px, 0, 0)`;
		}
	}

	render() {
		this.scrollY = $(window).scrollTop()

		const bounding = this.hero.getBoundingClientRect();
		const distance = (window.innerHeight + this.scrollY) - (bounding.top + this.scrollY);
		const percentage = distance / ((window.innerHeight + bounding.height) / 100);

		if (percentage > 0 && percentage < 100) {
			this.animate();
		}
	}

	clear() {
		this.delta = 0;
		this.velo = null;
	}

	create() {
		gsap.ticker.add(this.render.bind(this));
	}
}

function lerp(start, end, amt) {
	return (1 - amt) * start + amt * end
}






$(window).on("resize", function() {
	if ($(this).width() > 1025) {
		if (window.device == 'mobile') {
			location.reload()
		}
		window.device = 'desktop';
	} else {
		if (window.device == 'desktop') {
			location.reload()
		}
		window.device = 'mobile';
	}
}).trigger("resize")





const lenis = new Lenis({
	lerp: 0.08,
	easing: (t) => t === 1 ? 1 : 1 - Math.pow(2, -10 * t)
})

// lenis.on('scroll', ScrollTrigger.update)

function raf(time) {
	lenis.raf(time)
	requestAnimationFrame(raf)
}

requestAnimationFrame(raf)


if (device == 'mobile') {
	lenis.destroy()
}





$(".scrolldown").on("click", function() {
	gsap.to(window, {
		duration: 1.2,
		scrollTo: $(window).height() * 3,
		ease: 'power2.inOut',
	});
})




function menuHandler(el) {
	const $menutl = gsap.timeline({
		paused: true,
	}).from($(".en", el), {
		duration: 1.2,
		opacity: 0,
		yPercent: 100,
		ease: 'power3.out',
		stagger: {
			each: .11,
			// from: "end",
		},
	}).from($(".line", el), {
		duration: 1.5,
		scaleX: 0,
		transformOrigin: 'left',
		ease: 'power2.out',
		stagger: {
			each: .11,
			// from: "end",
		},
	}, "<.5")


	$("#menu").on("click", function() {
		$(this).toggleClass("is-open")
		$("#topmenuWrap").toggleClass("is-open")

		if ($(this).hasClass("is-open")) {
			$("body").addClass("is-lock")
			$menutl.play(0)
		} else {
			$menutl.reverse()
			gsap.delayedCall(0.5, function() {
				$("body").removeClass("is-lock")
			});
		}
	})
}




if (navigator.appVersion.indexOf("Mac") != -1) {
	$("body").get(0).style.setProperty('--lock', "15px");
} else {
	$("body").get(0).style.setProperty('--lock', "17px");
}







$(window).on("load", function() {
	$("#preload").fadeOut(500)


	$("[data-from").each(function(i, el) {
		let _r = (device != 'mobile') ? el.dataset.from : el.dataset.from * 0.4
		let _p = $(window).width() / 2 * _r

		gsap.fromTo(el, {
			x: `${-_p}px`,
		}, {
			scrollTrigger: {
				trigger: el,
				start: `top 100%`,
				end: `center 60%`,
				scrub: 0.8,
				// markers: true,
			},
			x: 0,
			ease: "none",
		});
	})


	$("[data-x]").each(function(i, el) {
		let _r = (device != 'mobile') ? el.dataset.x : el.dataset.x * 2
		let _p = $(window).width() / 2 * _r

		gsap.fromTo(el, {
			x: `${-_p}px`,
		}, {
			scrollTrigger: {
				trigger: el,
				start: `top 100%`,
				end: `bottom 0%`,
				scrub: 0.8,
				// markers: true,
			},
			x: `${_p}px`,
			ease: "none",
		});
	})


	$("[data-enter]").each((i, el) => {
		let enter = el.dataset.enter
		let s = ''

		if (enter == '') {
			s = 'top 100%'
		} else {
			s = `top ${enter}%`
		}

		ScrollTrigger.create({
			toggleActions: "play pause resume reverse", //重覆觸發
			trigger: el,
			start: s,
			end: "bottom 0%",
			// markers: true,
			onToggle: ({ progress, direction, isActive }) => {
				if (isActive) {
					$(el).addClass("is-enter")
				} else {
					$(el).removeClass("is-enter")
				}
			}
		});
	})


	let observer = new IntersectionObserver(function(entries, observer) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('is-enter');
			} else {
				entry.target.classList.remove('is-enter');
			}
		});
	}, {
		root: null, // 表示要觀察的根元素為視窗
		threshold: 0 // 表示元素只要有一點進入視窗就觸發
	});

	$("[data-horizon]").each((i, el) => {
		observer.observe(el);
	});


	ScrollTrigger.refresh();
})